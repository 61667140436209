import { template as template_fd3a3e9342024751b7a9eeede1145594 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_fd3a3e9342024751b7a9eeede1145594(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
